import React from 'react'
import "./Results.css"

function Results() {
  return (
    <div className='results'>
        <p>Results will be available once published! Stay tuned!</p>
    </div>
  )
}

export default Results