import "./styles/App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Events from "./components/Events";
import Register from "./components/Register";
import Results from "./components/Results";
import { useEffect } from "react";
import Redir from "./components/Redir";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAS2J4IUG52wSP9RMu4qgv1KBT8MzG_HZs",
  authDomain: "pratitya-6b78c.firebaseapp.com",
  projectId: "pratitya-6b78c",
  storageBucket: "pratitya-6b78c.appspot.com",
  messagingSenderId: "712726488234",
  appId: "1:712726488234:web:65c9a0ef99ffc696ff1cd3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  useEffect(() => {}, []);
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          {/* <Route path="/" element={<Redir />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/Register/:id" element={<Register />} />
          <Route path="/events/results" element={<Results />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
